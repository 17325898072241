import { fetchEvents } from "../../api/ticketmaster-events";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { EventCard } from "../Index/Events/EventCard";

interface Props {}

function Hello(props: Props) {
  const {} = props;

  const query = useQuery({
    queryKey: ["fix"],
    queryFn: () => {
      return fetchEvents({
        category: "festival",
        time_query: "all",
        genre: "All",
        location: {
          lat: 30.266666,
          lng: 97.73333,
        },
        within_miles: '999999999999999999',
        customDates: {
          start: new Date(),
          end: new Date("2025-05-05"),
        },
      });
    },
  });

  if (query.status === "error") {
    return <div>Loading...</div>;
  }

  if (query.isLoading) {
    return <div>Loading...</div>;
  }

  console.log(query.data);

  return (
    <div className="grid grid-cols-5 gap-8 max-w-[1600px] mx-auto py-20">
      {query.data?.map((event) => (
        <EventCard event={event} />
      ))}
    </div>
  );
}

export default Hello;
