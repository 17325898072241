import { z } from "zod";

const GeoLocationSchema = z.object({
  type: z.literal("Point"),
  coordinates: z.tuple([z.number(), z.number()]),
});

const EventVenueSchema = z.object({
  name: z.string().optional(),
  state: z.string().optional(),
  city: z.string().optional(),
  zip_code: z.string().optional(),
  country: z.string().optional(),
  geoLocation: GeoLocationSchema,
});

const EventSchema = z.object({
  _id: z.string(),
  event_name: z.string(),
  event_type: z.string(),
  banner_image: z.string().url(),
  about: z.string().nullable(),
  event_website_url: z.string().url(),
  event_start_date: z.string().datetime(),
  event_end_date: z.string().nullable(),
  event_venue: EventVenueSchema,
  event_genre_type: z.array(z.string()),
  non_traditional_event_genre_type: z.unknown(),
  sport: z.string().or(z.undefined()),
  event_spotify_playlist: z.string().nullable(),
  event_apple_playlist: z.string().nullable(),
  ticket_limit: z.number(),
  is_trending: z.boolean(),
  status: z.boolean(),
  event_status: z.string(),
  is_deleted: z.boolean(),
  event_source: z.string(),
  external_event_id: z.string(),
  tracking_url: z.string().url(),
  tickets: z
    .array(
      z.object({
        ticket_types: z
          .array(
            z.object({
              price: z.number().nullish(),
            })
          )
          .or(z.undefined()),
      })
    )
    .or(z.undefined()),
  /*   sport: z.string(),
  league: z.string(), */
  event_times: z.array(z.unknown()),
  __v: z.number(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  genre: z.array(
    z.object({
      name: z.string().nullable(),
    })
  ),
});

const EventArraySchema = z.array(EventSchema);

export type Event = z.infer<typeof EventSchema>;
export type EventArray = z.infer<typeof EventArraySchema>;

export { EventSchema, EventArraySchema };
