import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AppRoutes from "./Routes/AppRoutes";
import "react-toastify/dist/ReactToastify.css";
import { Provider, useDispatch } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "../src/redux/Store/store";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import { SocketProvider } from "./context/SocketContext";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { getUserByToken } from "./api/api";
const queryClient = new QueryClient();

const App = () => {

  return (
    <>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <PersistGate loading={<>Loading....</>} persistor={persistor}>
            <SocketProvider>
              <BrowserRouter>
                <AppRoutes />
              </BrowserRouter>
            </SocketProvider>
          </PersistGate>
        </QueryClientProvider>
        <ToastContainer />
      </Provider>
    </>
  );
};

export default App;
