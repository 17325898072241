import { getUserPreferences } from "@/api/api";
import { Button } from "./Button";
import { useQuery } from "@tanstack/react-query";
import React from "react";

import { isValid, z } from "zod";
import UpdatePreferences from "../../modals/updatePreferences/UpdatePreferences";
import { Navigate, useNavigate } from "react-router-dom";
import { CompleteProfileModal } from "./CompleteProfileModal";
import { useDispatch } from "react-redux";
import {
  closeUpdateProflie,
  openSignup,
  openTribe,
} from "../../redux/Reducers/modalReducer";
import { PreferenceHorizontalIcon } from "hugeicons-react";
import dayjs from "dayjs";
import UpdateProfile from "../../modals/updateProfile/UpdateProfile";

const userPreferenceSchema = z.object({
  _id: z.string(),
  gender_preference: z.enum(["nonbinary", "male", "female"]),
  age_preference: z.number().int().positive(),
  music_preferenece: z.array(z.string()),
  user_id: z.string(),
  is_deleted: z.boolean(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  __v: z.number().int(),
});

export type UserPreference = z.infer<typeof userPreferenceSchema>;

const userSchema = z.object({
  _id: z.string(),
  address: z.object({
    loc: z.object({
      type: z.literal("point"),
      coordinates: z.tuple([z.number(), z.number()]),
    }),
    name: z.string(),
  }),
  phone_detail: z.object({
    country_code: z.string(),
    phone_number: z.string(),
    _id: z.string(),
  }),
  profile_pic: z.string().url(),
  // dont use these fields. They are buggy and redundant
  is_phone_number_verified: z.boolean(),
  is_email_verified: z.boolean(),
  is_profile_completed: z.boolean(),
  is_profile_pic_added: z.boolean(),
  is_profile_pic_skipped: z.boolean(),
  is_preference_added: z.boolean(),
  is_music_payment_account_added: z.boolean(),
  is_social_accounts_added: z.boolean(),
  is_social_accounts_skipped: z.boolean(),
  is_captacha_verified: z.boolean(),
  firebase_token: z.string().nullable(),
  lastLoginDate: z.string().datetime(),
  is_deleted: z.boolean(),
  social_connections: z.array(z.unknown()),
  social_media: z.array(z.unknown()),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  __v: z.number().int(),
  first_name: z.string().or(z.null()),
  dob: z.string().datetime().or(z.null()),
  email: z.string().email(),
  gender: z.enum(["male", "female", "other"]).or(z.null()),
  last_name: z.string(),
});

export type User = z.infer<typeof userSchema>;

export const eventPageSchema = z.object({
  _id: z.string(),
  event_name: z.string(),
  event_type: z.string(),
  banner_image: z.string().url(),
  about: z.string(),
  event_website_url: z.string().url(),
  event_start_date: z.string().datetime(),
  event_end_date: z.string().datetime().nullable(),
  event_venue: z.object({
    name: z.string(),
    state: z.string(),
    city: z.string(),
    zip_code: z.string(),
    country: z.string(),
    geoLocation: z.object({
      type: z.literal("Point"),
      coordinates: z.tuple([z.number(), z.number()]),
    }),
  }),
  event_genre_type: z.array(z.string()),
  non_traditional_event_genre_type: z.array(z.nullable(z.string())),
  event_spotify_playlist: z.string().url().nullable(),
  event_apple_playlist: z.string().url().nullable(),
  ticket_limit: z.number().int(),
  is_trending: z.boolean(),
  status: z.boolean(),
  event_status: z.enum(["true", "false"]),
  is_deleted: z.boolean(),
  event_source: z.string(),
  external_event_id: z.string(),
  tracking_url: z.string().url(),
  event_times: z.array(z.unknown()),
  __v: z.number().int(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  genere: z.object({
    name: z.string(),
  }),
  nonTrendingGenere: z.array(z.unknown()),
  performer: z.array(z.unknown()),
  event_tickets: z.array(
    z
      .object({
        _id: z.string(),
        category: z.string(),
        weekend: z.string(),
        dates: z.array(z.unknown()),
        price: z.number(),
        event_id: z.string(),
      })
      .or(z.undefined())
  ),
  lineup_data: z.array(z.unknown()),
});

export type EventPageEvent = z.infer<typeof eventPageSchema>;

interface Props {
  preferences: UserPreference;
  user: User;
  event: Event;
}

function VerifyUserInfo({ preferences, user, event }: Props) {
  const [hasClickedJoinTribe, setHasClickedJoinTribe] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const hasFirstName = user?.first_name;
  const hasDob = user?.dob;
  const hasGender = user?.gender;
  const isValidUser = hasFirstName && hasDob && hasGender;

  function onJoinTribeClick() {
    if (!user) {
      dispatch(openSignup());
      return;
    }
    isValidUser
      ? dispatch(openTribe({ eventId: event._id }))
      : setHasClickedJoinTribe(true);
  }

  function onGetTicketsClick() {
    if (!user) {
      dispatch(openSignup());
      return;
    }
    window.open(event.tracking_url, "_blank")

  }

  console.log(user, preferences, "goes here");

  return (
    <div>
      <div className="flex items-center gap-2 mt-4">
        <div style={{ boxSizing: "border-box" }}>
          <Button onClick={onJoinTribeClick} size="lg" variant={"default"}>
            Join Tribe
          </Button>
        </div>
        <div>
          <Button
            onClick={onGetTicketsClick}
            variant={"yellow"}
            size="lg"
          >
            GET TICKETS
          </Button>
        </div>
      </div>

      {hasClickedJoinTribe && (
        <UpdateProfile
          onProfileComplete={() => {
            setHasClickedJoinTribe(false);
            dispatch(openTribe({ event, user }));
          }}
          onCloseClick={() => {
            setHasClickedJoinTribe(false);
          }}
          show={hasClickedJoinTribe}
        />
      )}
      {/*      <UpdatePreferences show={true} /> */}

      {/*   <CompleteProfileModal
        onSuccess={() => {
          dispatch(openTribe({ event, user }));
        }}
        closeModal={() => {
          setHasClickedJoinTribe(false);
        }}
        user={user}
        isOpen={hasClickedJoinTribe}
        onOpenChange={setHasClickedJoinTribe}
      /> */}
    </div>
  );
}

export default VerifyUserInfo;
